import { createSlice } from "@reduxjs/toolkit";
// import moduleRoutesMap from "../../routeControl";
// import { removeLocalStorageToken } from "../../utils";
import logger from "../../utils/logger";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    userData: {},
    cityData: {},
    categoryData: {},
    bookingData: {},
    flightData: {},
  },
  reducers: {
    loginAction: (state, action) => {
      return (state = {
        ...state,
        userData: { ...action.payload },
      });
    },
    logoutAction: (state) => {
      return (state = {
        ...state,
        userData: {},
      });
    },
    addCityDataAction: (state, action) => {
      return (state = {
        ...state,
        cityData: { ...action.payload },
      });
    },
    addCategoryDataAction: (state, action) => {
      return (state = {
        ...state,
        categoryData: { ...action.payload },
      });
    },
    bookingDataAction: (state, action) => {
      return (state = {
        ...state,
        bookingData: { ...action.payload },
      });
    },
    flightBookingAction: (state, action) => {
      return (state = {
        ...state,
        flightData: { ...action.payload },
      });
    },
  },
});

export const {
  loginAction,
  logoutAction,
  addCityDataAction,
  addCategoryDataAction,
  bookingDataAction,
  flightBookingAction,
} = authSlice.actions;

export const login = (data) => async (dispatch) => {
  try {
    dispatch(loginAction(data));
  } catch (error) {
    logger(error);
  }
};

export const logout = () => async (dispatch) => {
  try {
    // removeLocalStorageToken();
    dispatch(logoutAction());
  } catch (error) {
    logger(error);
  }
};
export const cityDataDispatch = (data) => async (dispatch) => {
  try {
    dispatch(addCityDataAction(data));
  } catch (error) {
    logger(error);
  }
};
export const categoryDataDispatch = (data) => async (dispatch) => {
  try {
    dispatch(addCategoryDataAction(data));
  } catch (error) {
    logger(error);
  }
};

export const packageBookingDispatch = (data) => async (dispatch) => {
  try {
    dispatch(bookingDataAction(data));
  } catch (error) {
    logger(error);
  }
};
export const flightBookingDispatch = (data) => async (dispatch) => {
  try {
    dispatch(flightBookingAction(data));
  } catch (error) {
    logger(error);
  }
};

// export const selectUserData = (state) => state.auth.userData;
export const selectUserData = (state) => state?.auth?.userData;
export const getCityData = (state) => state?.auth?.cityData;
export const getCategoryData = (state) => state?.auth?.categoryData;
export const getBookingData = (state) => state?.auth?.bookingData;
export const getFlightBookingData = (state) => state?.auth?.flightData;
// console.log("STATTA", state?.auth?.userData);
export default authSlice.reducer;
